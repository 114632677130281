exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-contact-template-jsx": () => import("./../../../src/templates/contact-template.jsx" /* webpackChunkName: "component---src-templates-contact-template-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-home-template-jsx": () => import("./../../../src/templates/home-template.jsx" /* webpackChunkName: "component---src-templates-home-template-jsx" */),
  "component---src-templates-news-template-jsx": () => import("./../../../src/templates/news-template.jsx" /* webpackChunkName: "component---src-templates-news-template-jsx" */),
  "component---src-templates-single-news-template-jsx": () => import("./../../../src/templates/single-news-template.jsx" /* webpackChunkName: "component---src-templates-single-news-template-jsx" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

